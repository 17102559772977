<template>
  <div class="details">
    <el-row class="tab" :gutter="20">
      <el-col :span="8" class="headerPage">
        <el-page-header content="包裹详情" @back="close" />
        <el-button
          class="makeUp"
          @click="repairBundle"
          :disabled= "dataList.bundleState != 1"
          :loading="repairLoading"
          type="primary"
          size="medium"
          >补打包裹号</el-button
        >
      </el-col>
      <div id="promptContent"></div>
    </el-row>
    <el-row class="head">
      <el-col :span="24" class="title-item"> 包裹信息 </el-col>
    </el-row>
    <el-row :gutter="10" class="information">
      <slot />
    </el-row>
    <el-row class="head">
      <el-col :span="24" class="title-item"> 包裹轨迹 </el-col>
    </el-row>
    <div>
      <el-row :gutter="20" class="trajectory" style="padding: 10px 20px 20px">
        <el-col :span="4"><div>状态</div></el-col>
        <el-col :span="4"><div style="padding-left: 40px">日期</div></el-col>
        <el-col :span="5"><div style="padding-left: 50px">说明</div></el-col>
        <el-col :span="5"><div>操作人</div></el-col>
        <el-col :span="4"><div>系统定位</div></el-col>
      </el-row>
      <el-row class="trajectory-item" :gutter="20">
        <el-col :span="1" style="margin-left:8%">
          <div class="progressBar" :style="{ height: height + 'px' }">
            <el-steps direction="vertical">
              <el-step
                v-for="(item, index) in dataList.trajectoryList"
                :key="index"
                :class="
                  item.operateType == operateType
                    ? 'ending'
                    : index == length
                    ? ''
                    : 'middle'
                "
              >
                <i class="order" slot="icon" v-if="item.operateType == operateType">收</i>
                <i slot="icon" v-if="item.operateType != 6"></i>
                <i class="order" slot="icon" v-if="index == length">发</i>
              </el-step>
            </el-steps>
          </div>
        </el-col>
        <el-col
          style="padding-bottom: 28px"
          :span="22"
          v-for="(row, index) in dataList.trajectoryList"
          :key="index"
        >
          <el-col :span="4">
            <div>{{ row.operateTypeDesc }}</div>
          </el-col>
          <el-col :span="4"
            ><div>{{ row.createdTime }}</div></el-col
          >
          <el-col :span="6"
            ><div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.explanatory"
                placement="top-start"
              >
                <div class="ellipsis">
                  {{ row.explanatory }}
                </div>
              </el-tooltip>
            </div></el-col
          >
          <el-col :span="5"
            ><div style="padding-left: 40px">
              {{ row.userName }}
            </div></el-col
          >
          <el-col :span="5"
            ><div class="ellipsis">
              <el-tooltip
                class="item"
                effect="dark"
                :content="row.locationAddress"
                placement="top-start"
              >
                <div class="ellipsis">
                  {{ row.locationAddress }}
                </div>
              </el-tooltip>
            </div></el-col
          >
        </el-col>
      </el-row>
    </div>
    <div class="footer">
      <el-row class="head">
        <el-col :span="24" class="title-item"
          >衣物详情（件数：{{
            dataList.bundleClothesDetailList
              ? dataList.bundleClothesDetailList.length
              : 0
          }}）
        </el-col>
      </el-row>
      <div style="padding: 0 30px">
        <Table
          :stripe="true"
          :border-bool="false"
          :button-type="true"
          :current-value="current"
          :header-style="headerStyle"
          :serial-number="true"
          :height="'42vh'"
          :page="page"
          :table-data="dataList.bundleClothesDetailList"
          :title-list="titleListData"
          :total="total"
          @handleCurrentChange="handleCurrentChange"
          @operationClick="operationClick"
        />
      </div>
    </div>
    <el-dialog
      title="包裹图片"
      :visible.sync="IsShowPage"
      width="40%"
      :append-to-body="true"
      @close="switchClose"
    >
      <el-row style="margin-bottom: 18px; display: flex">
        <el-image
          v-for="(item, index) in imageList"
          v-show="index < 3"
          :key="index"
          :preview-src-list="imageList"
          :src="item"
          style="width: 80px; height: 80px; padding-right: 5px"
        />
        <span v-if="imageList ? imageList.length > 3 : false" class="imgs">
          +{{ imageList.length - 3 }}
        </span>
      </el-row>
      <div
        v-if="imageList.length == 0"
        style="text-align:center;padding-bottom:40px;font-size:18px"
      >
        暂无图片
      </div>
    </el-dialog>
    <!--衣物详情-->
    <pop-view
      id="order-detailViewPopView"
      :show="detailView"
      width="700px"
      title="衣物详情"
      @cancel="detailView = false"
    >
      <div class="menuView">
        <button
          :class="{ select: currentTab === item.val }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="handleDetailTab(item.val)"
        >
          {{ item.title }}
        </button>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px"
        v-if="currentTab === 'info'"
      >
        <div style="display: flex">
          <div class="infoView">
            <div>
              <label class="label-leftText">衣物名称：</label>
              <label>{{ clothes.clothesName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物号：</label>
              <label>{{ clothes.clothesNum }}</label>
            </div>
            <div>
              <label class="label-leftText">洗涤方式：</label>
              <label>{{ clothes.clothesServiceName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物状态：</label>
              <label>{{ clothes.clothesStatus }}</label>
            </div>
            <div>
              <label class="label-leftText">洗后效果：</label>
              <el-tooltip
                class="item"
                effect="dark"
                :content="clothes.effects"
                placement="top"
              >
                <label class="label-ellipsis">{{ clothes.effects }}</label>
              </el-tooltip>
            </div>
            <div>
              <label class="label-leftText">是否售后：</label>
              <label>{{ clothes.isAfterSale }}</label>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">颜色：</label>
              <label>{{ clothes.colorName }}</label>
            </div>
            <div>
              <label class="label-leftText">品牌：</label>
              <label>{{ clothes.brandName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物价格：</label>
              <label>¥{{ clothes.currentPrice }}</label>
            </div>
            <div>
              <label class="label-leftText">单价：</label>
              <label v-if="clothes.unitPrice">¥{{ clothes.unitPrice }}</label>
              <label v-else>0</label>
            </div>
            <div>
              <label class="label-leftText">平方数：</label>
              <label v-if="clothes.squareNum">{{ clothes.squareNum }}m²</label>
              <label v-else>0m²</label>
            </div>
            <div>
              <label class="label-leftText">售后状态：</label>
              <label>{{ clothes.afterSaleStatusDesc }}</label>
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div class="infoView">
            <div>
              <label class="label-leftText">瑕疵：</label>
              <el-tooltip
                class="item"
                effect="dark"
                :content="clothes.blemishes"
                placement="top"
              >
                <label class="label-ellipsis">{{ clothes.blemishes }}</label>
              </el-tooltip>
            </div>
            <div>
              <label class="label-leftText">备注：</label>
              <el-tooltip
                class="item"
                effect="dark"
                :content="clothes.remark"
                placement="top"
              >
                <label class="label-ellipsis">{{ clothes.remark }}</label>
              </el-tooltip>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">预计取衣时间：</label>
              <label>{{ clothes.estimatedWashTime }}</label>
            </div>
            <div>
              <label class="label-leftText">工厂时效：</label>
              <label>{{ clothes.factoryAging }}天</label>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            margin-top: 20px;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: space-between;
          "
        >
          <div v-if="clothes.photos !== null">
            <el-image
              style="margin-left: 10px; width: 22%; margin-bottom: 10px"
              v-for="url in clothes.photos"
              :key="url"
              :src="url"
              :preview-src-list="clothes.photos"
              lazy
            ></el-image>
          </div>
        </div>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px"
        v-if="currentTab === 'status'"
      >
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in clothesStatusList"
            :key="index"
            color="#0bbd87"
            :timestamp="activity.operateTime"
          >
            {{ activity.description }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </pop-view>
  </div>
</template>

<script>
import Table from "@/components/table2";
import { getLodop } from "@/print/LodopFuncs";
import print from "@/api/print/print";
import PopView from "@/components/PopView";
import { repairBundle } from "@/api/clothes/order";
export default {
  name: "",
  components: {
    Table,
    PopView,
  },
  props: {
    buttonDisplay: {
      type: Boolean,
      default: false,
    },
    viewSwitch: {
      type: Boolean,
      default: false,
    },
    trackData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: [String, Number],
    },
    length: {
      type: [String, Number],
    },
    operateType: {
      type: [String, Number],
    },
    dataList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    imageList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    viewSwitch(val) {
      this.IsShowPage = val;
    },
    tips(val) {
      console.log(val, 111);
    },
  },
  data() {
    return {
      page: true,
      detailView: false,
      headerStyle: {
        fontSize: "14px",
        background: "#fff",
        color: "#363636",
      },
      titleListData: [
        {
          prop: "outClothesNum",
          label: "条码号",
          width: "155",
        },
        {
          prop: "clothesStatus",
          label: "衣物状态",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
        },
        {
          prop: "colorName",
          label: "颜色",
        },
        {
          prop: "orderOriginalPrice",
          label: "原价/折后价",
        },
        {
          prop: "squareNum",
          label: "平方数",
        },
        {
          prop: "couponId",
          label: "优惠券",
        },
        {
          prop: "remark",
          label: "店长备注",
        },
      ],
      total: 0,
      current: 1,
      repairLoading: false,
      clothes: {},
      clothesStatusList: [],
      tabs: [
        { val: "info", title: "信息" },
        { val: "status", title: "状态" },
      ],
      currentTab: "info",
      IsShowPage: false,
      tips: false,
    };
  },
  methods: {
    operationClick({ code, row }) {
      let _this = this;
      _this.clothesStatusList = [];
      _this.clothes = row;
      _this.clothes.clothesStatusList.forEach(function(item, index) {
        if (item.active) {
          _this.clothesStatusList.push(item);
        }
      });
      this.detailView = true;
    },
    handleDetailTab(val) {
      this.currentTab = val;
    },
    handleCurrentChange() {},
    close() {
      this.$emit("close");
    },
    switchClose() {
      this.$emit("switchClose");
    },
    //补打包裹号
    repairBundle() {
      let params = {
        bundleId: this.dataList.bundleId,
      };
      this.repairLoading = true;
      repairBundle(params).then((res) => {
        if (res.data.code == 0) {
          this.printPaper(res.data.data);
        } else {
          this.$message.error(res.data.msg);
        }
        this.repairLoading = false;
      });
    },
    printPaper(data) {
      let LODOP = getLodop();
      if (LODOP != undefined) {
        LODOP.SET_LICENSES(
          "",
          "660FDFD7D9F7F7101A272F2EB57C306A",
          "C94CEE276DB2187AE6B65D56B3FC2848",
          ""
        );
        print.logisticsReceipt(LODOP, data);
      } else {
        document.getElementById(
          "promptContent"
        ).innerHTML = `<font color='#FF00FF'>Web打印服务CLodop未安装启动，点击这里<a href='CLodop_Setup_for_Win32NT.exe' target='_self'>下载执行安装</a>
          <br>（若此前已安装过，可<a href='CLodop.protocol:setup' target='_self'>点这里直接再次启动</a>），成功后请刷新本页面。</font>`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  // position: relative;
  // background: #fff;
  // width: 100%;
  // height: 50px;
  // padding: 20px 20px 20px 80px;
  // z-index: 100;
  &::-webkit-scrollbar {
    display: none;
  }
  .headerPage {
    display: flex;
    text-align: center;
    padding-bottom: 10px;
    .makeUp {
      position: absolute;
      left: 200px;
      top: -5px;
    }
  }
  .head {
    margin: 10px 0;
    padding: 15px 0;
    background-color: #ebeff0;
    .title-item {
      color: #000;
      font-size: 16px;
      padding-left: 30px;
    }
  }
  .information {
    text-align: center;
    line-height: 35px;
    padding-left: 20px;
    .el-col {
      text-align: center;
      padding-top: 10px;
      font-size: 14px;
      color: #000;
      font-weight: 700;
    }
  }
  .trajectory {
    padding: 10px 20px 20px;
    .el-col {
      div {
        padding: 0 30px;
        font-size: 14px;
      }
    }
  }
  .trajectory-item {
    padding: 10px 20px 0px;
    .el-col {
      div {
        padding: 0 10px;
        font-size: 14px;
      }
    }
  }
  .progressBar {
    position: absolute;
    width: 50px;
    padding: 5px 0 0 11%;
  }
}
.imgs {
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 18px;
  background-color: #e8e8e8;
  cursor: pointer;
}
::v-deep .el-step__icon.is-text {
  border-radius: 0%;
  background-color: #959292;
  transform: rotate(45deg);
}
::v-deep .ending {
  .el-step__icon.is-text {
    border-radius: 0%;
    background-color: #b30d0d;
    border: 1px solid #b30d0d;
    transform: rotate(45deg);
  }
}
::v-deep .middle {
  .el-step__icon {
    width: 12px;
    height: 12px;
    left: 6px;
    top: -7px;
  }
  .el-step__icon.is-text {
    border-radius: 0%;
    background-color: #959292;
    border: #959292;
    transform: rotate(45deg);
  }
}
::v-deep .el-step__head.is-finish {
  border-color: #959292;
}
.order {
  color: #fff;
  transform: rotate(-45deg);
}
::v-deep .el-step__line {
  background-color: #fff;
  background-image: linear-gradient(
    to bottom,
    #cccaca 50%,
    #cccaca 50%,
    transparent 0%
  );
  background-size: 1px 11px;
  background-repeat: repeat;
  width: 1px;
  border: unset;
}
::v-deep .el-step__line-inner {
  background-image: linear-gradient(
    to bottom,
    #cccaca 50%,
    #cccaca 50%,
    transparent 0%
  );
  background-size: 1px 50px;
  background-repeat: repeat;
  width: 1px;
  border: unset;
}

::v-deep .el-page-header__content {
  padding: 0px 20px 0 0;
}
#order-detailViewPopView > .popView-contentView {
  > .menuView {
    position: absolute;
    width: 88px;
    display: flex;
    top: 45px;
    left: 0;
    right: 0;
    margin: auto;

    > button {
      font-size: 16px;
      color: #333;
      flex: 1;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }

    > button.select {
      position: relative;
      color: #3370ff;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
    }

    > button.select:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0px;
      position: absolute;
      width: 24px;
      height: 2px;
      background: #3370ff;
      margin: auto;
      border-radius: 1px;
    }
  }

  > .contentView {
    .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 99px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.label-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 210px;
}
</style>
